import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  // ButtonDropdown,
  // Dropdown,
  // DropdownMenu,
  // DropdownToggle,
  // DropdownItem,
  Label,
  Badge,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  Card,
  CardImg, CardText, CardBody,
  CardTitle, CardSubtitle
} from "reactstrap";
import { AttachFile } from '@material-ui/icons'
import Widget from "../../components/Widget/Widget.js";
// import TaskContainer from "./components/TaskContainer/TaskContainer.js";

// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import MUIDataTable from "mui-datatables";

import cloudIcon from "../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../assets/tables/optionsIcon.svg";
import printerIcon from "../../assets/tables/checkmark-outline.svg";
import searchIcon from "../../assets/tables/searchIcon.svg";
// import moreIcon from "../../assets/tables/moreIcon.svg";

import s from "./Tables.module.scss";
import mock from "./mock.js";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Tables = function ({ rows }) {
  const user = useSelector(loginUser => loginUser);
  const { nome, perfil, CPF } = JSON.parse(localStorage.userPortal)
  const [show, setShow] = useState(false);
  const [pendencias, setPendencias] = useState([]);
  const [idSimulacao, setIdSimulacao] = useState(0);
  const [motivo, setMotivo] = useState(null);
  const [qtdPendencia, setQTD] = useState([]);


  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setIdSimulacao(id);
    handleSetPendencias(id);
  };
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  const [firstTable, setFirstTable] = useState([]);
  // const [secondTable] = useState(mock.secondTable);
  // const [transactions, setTransactions] = useState(mock.transactionsWidget);
  // const [tasks, setTasks] = useState(mock.tasksWidget);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  // const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  // const [tableDropdownOpen, setTableMenuOpen] = useState(false);

  const pageSize = 4;
  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);
  // const secondTablePagesCount = Math.ceil(secondTable.length / pageSize);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  }

  const toggle = () => {
    setShow(!show)
  }

  const handleFetchConsultas = async () => {
    fetch(`https://api-barao.herokuapp.com/get/simulacoes/${CPF}/${perfil}`).then(async f => {
      const ret = await f.json();
      await ret.map((f, idx) => {
        handleSetPendencias(f.ID, idx);
      })
      setFirstTable(ret);
    })
  };

  const getColor = (status) => {
    let color = 'secondary-red';
    switch (parseInt(status)) {
      case 0:
        color = 'secondary-cyan';
        break;
      case 1:
        color = 'success';
        break;
      case 2:
        color = 'secondary-red'
        break;
      default:
        break;
    }

    return color;
  };

  const getStatus = (status) => {
    let statusN = 'Aguardando';
    switch (parseInt(status)) {
      case 0:
        statusN = 'Aguardando';
        break;
      case 1:
        statusN = 'Aprovado';
        break;
      case 2:
        statusN = 'Cancelado'
        break;
      default:
        break;
    }

    return statusN;
  };

  const handleSetPendencias = (id = null, idx = null) => {
    fetch(`https://api-barao.herokuapp.com/get/pendencias/${id !== null ? id : idSimulacao}`).then(async f => {
      const pend = await f.json();
      setPendencias(pend);
      let qtdPend = qtdPendencia;
      qtdPend[idx] = pend.length;
      setQTD(qtdPend);
    })
  }


  const handleSavePendencia = () => {
    fetch(`https://api-barao.herokuapp.com/insert/pendencia/${idSimulacao}/${motivo}`).then(async f => {
      // handleSetPendencias();
    })
  }

  useEffect(() => {
    handleFetchConsultas();
    handleSetPendencias(null, 0);
  }, []);

  const handleSaveAnexo = (id) => {

  }

  const getPend = () => {

  }
  const handleAprova = (id) => {
    fetch(`https://api-barao.herokuapp.com/update/aprova/${id}`).then(async f => {
      const ret = await f.json();
      if (ret.mensagem === 'aprovado') {
        alert('Aguarde contato da nossa equipe de vendas!');
        window.location.assign("http://portal.baraocredbrasil.com.br/");
        return;
      }
    })
  }

  const renderPendencias = () => {
    return pendencias.map(f => {
      return (
        <div style={{ marginBottom: 15 }}>
          <Card>
            <CardBody>
              <CardSubtitle>Motivo:</CardSubtitle>
              <CardText>{f.DESCRICAO}</CardText>
              <Button onClick={() => handleSaveAnexo(f.ID)}>Anexar Documentação</Button>
            </CardBody>
          </Card>
        </div>
      )
    })
  }

  return (
    <div>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">Minhas Propostas</div>
                  <div className="d-flex">
                    {/* <a href="/#"><img src={searchIcon} alt="Search" /></a> */}
                    {/* <a href="/#"><img className="d-none d-sm-block" src={cloudIcon} alt="Cloud" /></a> */}
                    <a href="/#"><img style={{ width: 24 }} src={printerIcon} alt="Printer" /></a>
                    <a title="Tutorial de Liberação de BANCO e Mudança de Modalidade de Saque" href="/#/template/treinamento"><img className="d-none d-sm-block" src={optionsIcon} alt="Options" /> Tutorial</a>
                    {/* <a href="/#"><img src={funnelIcon} alt="Funnel" /></a> */}
                  </div>
                </div>
                <div className="widget-table-overflow">
                  <Table className={`table-striped table-borderless table-hover ${s.statesTable}`} responsive>
                    <thead>
                      <tr>
                        <th className={s.checkboxCol}>
                          <div className="checkbox checkbox-primary">
                            <input
                              className="styled"
                              id="checkbox100"
                              type="checkbox"
                            />
                            <label for="checkbox100" />
                          </div>
                        </th>
                        <th className="w-25">Nome</th>
                        <th className="w-25">valor liberado</th>
                        <th className="w-25">Banco</th>
                        <th className="w-25">aprovado cliente?</th>
                        <th className="">status</th>
                        <th className="">Pendências</th>
                        <th className="">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {firstTable
                        .slice(
                          firstTableCurrentPage * pageSize,
                          (firstTableCurrentPage + 1) * pageSize
                        )
                        .map((item, idx) => (
                          <tr key={uuidv4()}>
                            <td>
                              <div className="checkbox checkbox-primary">
                                <input
                                  id={item.ID}
                                  className="styled"
                                  type="checkbox"
                                />
                                <Label for={item.id} />
                              </div>
                            </td>
                            <td><span className="ml-3">{item.NOME}</span></td>
                            <td>{item.VALOR_LIBERADO}</td>
                            <td>{item.BANCO}</td>
                            <td>{parseInt(item.APROVADO_CLIENTE) === 0 ?
                              <a className={s.linkTutorial} href={`/#/template/treinamento/${item.ID}`}>
                                <Badge pill color="secondary-red">Clique para realizar a liberação</Badge>
                              </a>
                              : 'Sim'}
                            </td>
                            <td><Badge color={getColor(item.STATUS)}>{getStatus(item.STATUS)}</Badge></td>
                            <td style={{ textAlign: 'center' }}>
                              <Button style={{ background: 'transparent', border: 'none' }} onClick={() => handleShow(item.ID)}>
                                <Badge pill color="secondary-red"><AttachFile /></Badge>
                              </Button>
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {
                                perfil !== 'Operador' ?
                                  parseInt(item.APROVADO_CLIENTE) === 0 ?
                                    <Button style={{ background: 'transparent', border: 'none' }} onClick={() => handleAprova(item.ID)}>
                                      <Badge pill color="secondary-red">Liberar</Badge>
                                    </Button> : <Badge pill color="success">Liberado</Badge>
                                  : ''
                              }
                              {perfil === 'Operador' ?
                                <Button style={{ background: 'transparent', border: 'none' }} variant="link" onClick={() => handleShow(item.ID)}>
                                  <Badge pill color="success">Histórico</Badge>
                                </Button> : ''
                              }
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <Pagination className="pagination-borderless" aria-label="Page navigation example">
                    <PaginationItem disabled={firstTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={e => setFirstTablePage(e, firstTableCurrentPage - 1)}
                        previous
                        href="#top"
                      />
                    </PaginationItem>
                    {[...Array(firstTablePagesCount)].map((page, i) =>
                      <PaginationItem active={i === firstTableCurrentPage} key={i}>
                        <PaginationLink onClick={e => setFirstTablePage(e, i)} href="#top">
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    <PaginationItem disabled={firstTableCurrentPage >= firstTablePagesCount - 1}>
                      <PaginationLink
                        onClick={e => setFirstTablePage(e, firstTableCurrentPage + 1)}
                        next
                        href="#top"
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
              </Widget>
            </Col>
          </Row>

        </Col>
      </Row>
      <Modal isOpen={show} toggle={toggle}>
        <ModalHeader toggle={toggle}>Pendências</ModalHeader>
        <ModalBody>
          {perfil === 'Operador' && (
            <div style={{ marginBottom: 15 }}>
              <Card>
                <CardBody>
                  <CardTitle>Incluir Pendência</CardTitle>
                  <CardText><textarea onChange={(e) => setMotivo(e.target.value)} style={{
                    width: '100%',
                    border: '2px red solid',
                    borderRadius: 5,
                    minHeight: 120,
                    padding: 15
                  }} /></CardText>
                  <Button onClick={() => handleSavePendencia()}>Salvar</Button>
                </CardBody>
              </Card>
            </div>
          )}
          <hr />
          <CardTitle>Histórico</CardTitle>
          {renderPendencias()}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Ok</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Tables;
