import React from "react";
import {
  Badge,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import s from "./ErrorPage.module.scss";

import errorImage from "../../assets/errorImage.svg"
import FooterIcon from "../../components/Icons/FooterIcon.js";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import bg01 from '../../assets/tutorial/b01.jpg';
import bg02 from '../../assets/tutorial/b02.jpg';
import bg03 from '../../assets/tutorial/b03.jpg';
import bg04 from '../../assets/tutorial/b04.jpg';
import bg05 from '../../assets/tutorial/b05.jpg';
import bg06 from '../../assets/tutorial/b06.jpg';
import bg07 from '../../assets/tutorial/b07.jpg';
import bg08 from '../../assets/tutorial/b08.jpg';
import bg09 from '../../assets/tutorial/b09.jpg';
import { useState } from "react";
import { useEffect } from "react";

const aprovar = (id) => {
  fetch(`https://api-barao.herokuapp.com/update/aprova/${id}`).then(async f => {
    const ret = await f.json();
    if (ret.mensagem === 'aprovado') {
      alert('Aguarde contato da nossa equipe de vendas!');
      window.location.assign("http://portal.baraocredbrasil.com.br/");
      return;
    }
  })
}

const ErrorPage = () => {
  const [idSol, setIDSol] = useState(null);

  useEffect(() => {
    const id = window.location.href.split("/").pop();
    setIDSol(id);
  }, []);

  return (
    <div className={s.pageContainer}>
      <div className={s.errorContainer}>
        <Carousel>
          <div>
            <img src={bg01} />
            <p className="legend">Tutorial de Liberação FGTS</p>
          </div>
          <div>
            <img src={bg02} />
            <p className="legend">Tutorial de Liberação FGTS</p>
          </div>
          <div>
            <img src={bg03} />
            <p className="legend">Tutorial de Liberação FGTS</p>
          </div>
          <div>
            <img src={bg04} />
            <p className="legend">Tutorial de Liberação FGTS</p>
          </div>
          <div>
            <img src={bg05} />
            <p className="legend">Tutorial de Liberação FGTS</p>
          </div>
          <div>
            <img src={bg06} />
            <p className="legend">Tutorial de Liberação FGTS</p>
          </div>
          <div>
            <img src={bg07} />
            <p className="legend">Tutorial de Liberação FGTS</p>
          </div>
          <div>
            <img src={bg08} />
            <p className="legend">Tutorial de Liberação FGTS</p>
          </div>
          <div>
            <img src={bg09} />
            <p className="legend">Tutorial de Liberação FGTS</p>
          </div>
          <div>
            <Button style={{
              position: 'absolute',
              top: ' 50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              fontSize: '22px'
            }}
              onClick={() => aprovar(idSol)}>
              Realizei as Etapas, <br />Desejo Prosseguir!
            </Button>
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default ErrorPage;
