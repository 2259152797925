export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export function receiveLogin() {
  return {
    type: LOGIN_SUCCESS
  };
}

function loginError(payload) {
  return {
    type: LOGIN_FAILURE,
    payload,
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

// logs the user out
export function logoutUser() {
  return (dispatch) => {
    dispatch(requestLogout());
    localStorage.removeItem('authenticated');
    localStorage.removeItem('userPortal');
    dispatch(receiveLogout());
  };
}

export function loginUser(creds) {
  return (dispatch) => {
    fetch(`https://api-barao.herokuapp.com/get/users/${creds.email}`).then(async f => {
      const usuarios = await f.json();
      let uCont = 0;
      usuarios.map(user => {
        if ((creds.email.length > 0 && creds.password.length > 0) && (creds.email === user.CPF && creds.password === user.SENHA)) {
          localStorage.setItem('authenticated', true);
          localStorage.setItem('userPortal', JSON.stringify({
            nome: user.NOME,
            perfil: user.PERFIL === 0 ? 'Cliente' : 'Operador',
            CPF: user.CPF
          }))
          dispatch(receiveLogin());
          uCont++;
          return;
        }
      })

      if (uCont === 0) {
        alert('Credenciais inválidas');
        dispatch(loginError('Something was wrong. Try again'));
      }
    });
  }
}

